import { Injectable } from '@angular/core';
import { Observable,Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class AppConfigureService {

  private readonly subject = new Subject<any>();
  logoChange(isChanged:any) {
    this.subject.next(isChanged);
}

getLogo(): Observable<any> {
    return this.subject.asObservable();
}
}
