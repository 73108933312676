import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Url } from 'src/app/shared/constants/url-constants';  

@Injectable({
  providedIn: 'root'
})
export class ConfigureService {

  constructor(private readonly http: HttpClient) { }
  getAppConfig() {
    const href = `${Url.USER}/client/config`;
    return this.http.get<any>(href);
  }

  saveOrUpdateAppConfig(payload) {
    const href = `${Url.USER}/client/config`;
    return this.http.post<any>(href, payload);
  }

 
}
