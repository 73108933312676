import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from '../environments/environment';
import { ConfigureService } from './features/configure/configure.service';
import { AppConfigureService } from './features/configure/app-configure.service';

@Component({
  selector: 'vc-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit  {

  clientDetails;
  constructor(
    private readonly title: Title,
    private readonly configureService:ConfigureService,
    private readonly appConfigService:AppConfigureService,
  ) {
    this.title.setTitle('Community');
  }
  
  
  ngOnInit() {
    if (!environment.enableCtrl) {
      this.disableControls();
    }
    this.updateAppConfig();
  }

   async updateAppConfig(){
    const token = localStorage.getItem('sessionToken');
      if(token!== null){
        await this.configureService.getAppConfig().subscribe((data:any) => {
          this.clientDetails = data;
            document.documentElement.style.setProperty('--primary-color', data.primaryAppColour);
            localStorage.setItem('logo',data.image);
            this.appConfigService.logoChange(data);
      });
    }
  }


  disableControls() {

    function keyPressCheck(event) {
      if (event.keyCode === 123) {
        return false;
      }
    }

    document.oncontextmenu = function () {
      return false;
    }

    function disableCtrlKeyCombination(e) {
      //list all CTRL + key combinations you want to disable
      const forbiddenKeys = new Array('a', 'n', 'c', 'x', 'v', 'j', 'w');
      let key, isCtrl;

      if (window.event) {
        key = (<any>window.event).keyCode; //IE
        if ((<any>window.event).ctrlKey) {
          isCtrl = true;
        } else {
          isCtrl = false;
        }
      } else {
        key = e.which; //firefox
        if (e.ctrlKey) {
          isCtrl = true;
        } else {
          isCtrl = false;
        }
      }
      //if ctrl is pressed check if other key is in forbidenKeys array
      if (isCtrl) {
        for (let i = 0; i < forbiddenKeys.length; i++) {
          //case-insensitive comparation
          if (forbiddenKeys[i].toLowerCase() === String.fromCharCode(key).toLowerCase()) {
            return false;
          }
        }
      }
      return true;
    }
  }

}
